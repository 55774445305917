@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900; /* Define the range of font weights */
  font-stretch: 75% 125%; /* Define the range of font stretches */
  src: url('./app/Assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf')
    format('truetype-variations');
}

/* You can add additional @font-face rules for different font styles if needed */

@tailwind base;
@tailwind components;
@tailwind utilities;

/** Class to revert back to base html styles  */

@layer base {
  .default-styles * {
    all: revert;
  }
}

.rounded-lg {
  border-radius: 1rem;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) / */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.tooltip::-webkit-scrollbar {
  display: none;
}

/* / For IE, Edge and Firefox / */
.scrollbar-hide {
  -ms-overflow-style: none;
}
